.oy-editor {
  & .p-editor-toolbar {
    background: rgb(var(--menu-bg));
    border-color: rgb(var(--border-line-color)) !important;
  }

  & .p-editor-content {
    border-color: rgb(var(--border-line-color)) !important;
  }

  & .ql-editor {
    background: rgb(var(--editor-bg)) !important;
    border-color: rgb(var(--border-line-color)) !important;
  }
}