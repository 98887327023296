.p-inputgroup > .p-inputtext {
  border-left: none;
  border-color: rgb(var(--border-line-color));

  &:enabled:focus {
    box-shadow: inherit;
    border-color: rgb(var(--focus-border-color));
  }
}

.p-inputtext {
  &:not(.p-placeholder) {
    color: rgb(var(--fc));
  }

  &.ng-dirty.ng-invalid {
    border-color: rgb(var(--input-border-error-color)) !important;
  }
}