.p-dropdown {
  background: rgb(var(--input-bg));
  border-color: rgb(var(--border-line-color));

  &:enabled:focus {
    box-shadow: inherit;
    border-color: rgb(var(--focus-border-color));
  }

  &:not(.p-disabled).p-focus {
    box-shadow: inherit;
    border-color: rgb(var(--focus-border-color));
  }

  & .p-dropdown-items {
    background: rgb(var(--input-bg));
    color: rgb(var(--fc));

    & .p-highlight {
      color: rgb(var(--secondary-color)) !important;
      background-color: rgb(var(--active-color)) !important;
    }

    & .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
      background-color: rgb(var(--hover-color)) !important;
    }

    & .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
      color: rgb(var(--secondary-color)) !important;
      background-color: rgb(var(--hover-color)) !important;
    }

    & .p-dropdown-item {
      color: rgb(var(--fc));
    }

  }
}


.p-dropdown-panel .p-dropdown-header {
  color: rgb(var(--fc));
  background: rgb(var(--input-bg));
}