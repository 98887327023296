body {
  margin: 0;
  min-height: 100vh;
  scroll-behavior: smooth;
  font-family: var(--ff);
  color: rgb(var(--fc));
  font-feature-settings: "salt";
}

* {
  font-family: var(--ff);
}

img {
  user-select: none;
}