.p-accordion {

  & .p-accordion-header-link {
    background: rgb(var(--menu-bg));
    color: rgb(var(--fc));
    border: 1px solid rgb(var(--border-line-color));
    font-weight: 500;
  }


  & .p-accordion-content {
    background: rgb(var(--admin-bg));
    border: 1px solid rgb(var(--border-line-color));
  }
}