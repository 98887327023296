.p-tabview {
  & .p-tabview-nav {
    background: rgb(var(--admin-bg));
    color: rgb(var(--fc));
    border-bottom: 1px solid rgb(var(--border-line-color));
  }

  & .p-tabview-nav-link, & .p-tabview-panels {
    background: rgba(var(--menu-bg), .3)
  }

  & li:not(.p-highlight) .p-tabview-nav-link {
    color: rgb(var(--fc));
    border-bottom: 1px solid rgb(var(--border-line-color));
  }

  & .p-tabview-panels {
    color: rgb(var(--fc));
  }
}