.oy-treetable {

  & .p-treetable-thead > tr > th {
    background: rgb(var(--admin-bg));
    border-color: rgb(var(--border-line-color));
  }
  
  &__permissions {
    background-color: rgb(var(--input-bg));
    border-bottom: 1px dashed rgb(var(--border-line-color));

    &--checked {
      background-color: rgba(var(--input-bg), .5)
    }  

  }  
  
  &__row {
    cursor: pointer;

    &:hover > td {
      background-color: rgb(var(--overlay-bg)) !important;
    }
  }

  &__permissions-bulk {
    background-color: rgb(var(--fourth-color));
    color: white;
    font-weight: 700;
    letter-spacing: .5px;
    border-bottom: 1px dashed rgb(var(--border-line-color));
  }
  

}