/* BOLD ITALIC */
@font-face {
  font-family: 'Helvetica Bold Italic';
  font-style: normal;
  font-weight: normal;
  src:
    local('Helvetica Bold Italic'),
    url('helvetica.bold.italic.otf') format('opentype');
}

/* SEMI BOLD ITALIC */
@font-face {
  font-family: 'Helvetica Semi Bold Italic';
  font-style: normal;
  font-weight: 600;
  src:
    local('Helvetica Semi Bold Italic'),
    url('helvetica.bold.italic.otf') format('opentype');
}
