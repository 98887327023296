.oy-table {

  & .p-datatable-wrapper {
    @apply scrollbar-light;
  }

  // HEADER
  & .p-datatable-thead > tr > th {
    background: rgb(var(--admin-bg));
    border-color: rgb(var(--border-line-color));
    text-transform: uppercase;
    font-size: var(--fs-xs);
    font-family: var(--ff-semibold);
  }
  
  // ROW
  & .p-datatable-tbody > tr {
    background: rgb(var(--admin-bg));
    color: rgb(var(--fc));
    font-size: var(--fs-xs);
    font-family: var(--ff-regular-light);

    // CELLS
    & td {
      border-color: rgb(var(--border-line-color));
    }
  }

  & .p-column-title {
    margin-right: 15px;
  }

}