/*
.p-confirm-popup {
  background: rgb(var(--menu-bg));
  color: rgb(var(--fc));
  border: 1px solid rgb(var(--border-line-color));
}
*/

body .p-stepper {
  & .p-stepper-action {
    background: transparent;
    color: rgb(var(--fc));
  }

  & .p-stepper-action .p-stepper-number {
    border-color: rgb(var(--admin-bg));
    background: rgb(var(--admin-bg));
    color: rgb(var(--fc));
  }

  & .p-stepper-panels {
    color: rgb(var(--fc));
    background: transparent;
  }
}
