.theme-switcher {
    width: 70px;
    top: 3px;

    & .p-inputswitch-slider::before {
        width: 2.5rem;
        height: 2.5rem;
        margin-top: -15px;
        left: 0.1rem;
        background: transparent;
        font-size: 20px;
        content: "🌞";
    }
    
    &.p-inputswitch-checked .p-inputswitch-slider::before {
        content: "🌚";
        transform: translateX(2.3rem);
    }
}