.input-errors {
  /* position: relative;
  background-color: #371e1e;
  border-radius: 5px;
  padding: 8px;

  &__message {
    color: #ff9494;
    display: block;
    font-size: var(--fs-xs);
    letter-spacing: 0.2px;
  } */

  position: relative;

  &__messages {
    &--center .p-message-wrapper {
      justify-content: center;
    }

    &--right .p-message-wrapper {
      justify-content: right;
    }

    &--padding .p-message-wrapper {
      padding: 0 12px !important;
    }

    & .p-message {
      background: none;
      margin: 0;

      & .p-message-wrapper {
        padding: 0;
        text-align: left;
      }

      & .p-message-wrapper .p-message-detail {
        font-size: 14px;
      }

      & .p-message-wrapper .p-message-icon {
        max-height: 30px;
        margin-right: 0;
      }
      & .p-message-wrapper .p-message-icon .p-icon-wrapper svg {
        width: 16px;
      }
    }
  }
}
