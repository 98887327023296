.sidebar-responsive {
  user-select: none;
  background: rgb(var(--menu-bg));
  border-right: 1px dashed rgb(var(--border-line-color));

  &__logo-container {
    height: 74px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    position: sticky;
    backdrop-filter: blur(7px);
    z-index: 1200;
  }

  &__logo {
    background-repeat: no-repeat;
    background-size: contain;
    background-image: var(--sidebar-logo-min);
    transition: width 0.125s ease-in-out;
    width: 48px;
    height: 48px;
    backdrop-filter: blur(8px);
    z-index: 1200;

    &--full {
      width: 130px;
      height: 33px;
      background-image: var(--sidebar-logo);
    }
  }

  &__menu-container {
    overflow-y: auto;
    @apply scrollbar-light;
    @apply scroll-bar-light-menu;
    height: calc(100vh - 85px);
    position: relative;
  }

  &__menu-tiered {
    position: relative;
    z-index: 1200;
    max-width: 90px;

    &:has(.p-menuitem-active) {
      position: fixed;
    }

    &.p-tieredmenu {
      background: rgb(var(--menu-expanded-bg));
      border: 0;
    }

    & .p-tieredmenu-root-list span {
      display: none;
    }

    & .p-tieredmenu-root-list a {
      justify-content: center;
      color: rgb(var(--fc));
      font-family: var(--ff-regular-light);
      font-weight: 300;
      font-size: var(--fs-xs);
      padding: 15px 20px;
      & i {
        color: rgb(var(--secondary-color));
        margin-right: 5px;
      };
    }

    & .p-submenu-list {
      background: rgb(var(--menu-expanded-bg));

      span {
        display: block;
      }
      a {
        justify-content: start;
      }
    }

    &
      .p-menuitem:not(.p-highlight):not(.p-disabled)
      > .p-menuitem-content:hover {
      background: rgb(var(--menu-bg));
      color: gray;
    }

    & .p-menuitem.p-highlight > .p-menuitem-content {
      background: rgb(var(--menu-bg));
      color: gray;
    }
  }

  &__menu {
    font-size: 13px;
    overflow-y: auto;
    overflow-x: hidden;
    &--slim .p-panelmenu-content {
      padding-left: 0;
    }

    &--full {
      .p-panelmenu-header-content a {
        padding-left: 30px;
      }
      .p-panelmenu-content a {
        padding-left: 45px;
      }
    }

    & .p-panelmenu-content {
      background: rgb(var(--menu-expanded-bg));
      border: 0;
      color: rgb(var(--menu-color));
    }

    & .p-menuitem-content a {
      color: rgb(var(--menu-color));
    }

    & .p-panelmenu-header .p-panelmenu-header-content {
      border: 0;
      background: transparent;
      color: gray;
      font-weight: 400 !important;
      letter-spacing: .5px;
    }

    & .p-panelmenu-content .p-menuitem:hover {
      color: rgb(var(--menu-item-color-hover));
      background: rgb(var(--menu-item-bg-hover));

      & > .p-menuitem-content {
        color: rgb(var(--menu-item-color-hover));
        background: transparent;
      }
    }

    &
      .p-panelmenu-content
      .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
      > .p-menuitem-content {
      color: rgb(var(--menu-item-color-hover));
      background: transparent;
    }
  }

  &__item-active {
    color: rgb(var(--secondary-color)) !important;
    font-weight: 600;
  }
}

.scroll-bar-light-menu::-webkit-scrollbar-track  {
  background-color: rgb(var(--menu-bg));
}