/* ITALIC */
@font-face {
  font-family: 'Helvetica Regular Italic';
  font-style: normal;
  font-weight: normal;
  src:
    local('Helvetica Regular Italic'),
    url('helvetica.italic.otf') format('opentype');
}

@font-face {
  font-family: 'Helvetica Regular Thin Italic';
  font-style: normal;
  font-weight: 100;
  src:
    local('Helvetica Regular Thin Italic'),
    url('helvetica.italic.otf') format('opentype');
}

@font-face {
  font-family: 'Helvetica Regular Light Italic';
  font-style: normal;
  font-weight: 300;
  src:
    local('Helvetica Regular Light Italic'),
    url('helvetica.italic.otf') format('opentype');
}

@font-face {
  font-family: 'Helvetica Regular Medium Italic';
  font-style: normal;
  font-weight: 500;
  src:
    local('Helvetica Regular Medium Italic'),
    url('helvetica.italic.otf') format('opentype');
}
