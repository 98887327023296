.p-component-overlay:not(.p-sidebar-mask) {
  z-index: 1200 !important;
}

.oy-modal {
  color: rgb(var(--fc));
  background: rgb(var(--admin-bg));

  & .p-dialog-header,.p-dialog-content {
    color: rgb(var(--fc));
    background: rgb(var(--admin-bg));
  }

  &__close {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: var(--fs-sm);
    color: rgb(var(--secondary-color));
    cursor: pointer;
  }
}