.p-toast {
  
  &.p-component {
    z-index: 1202 !important;
  }

  & .p-toast-message {
    font-size: var(--fs-xs);
    &.p-toast-message-error {
      background: #ffffff;
    }
  }
}
