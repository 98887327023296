.p-paginator {
  background: rgb(var(--admin-bg));
  border-color: transparent;

  & * {
    font-size: var(--fs-xs);
  }
  
  & .p-dropdown {
    height: 35px;
    & .p-dropdown-trigger {
      width: 35px;
    }
    & .p-inputtext {
      padding: 6px 0 6px 15px;
    }
  }

  & .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    border-color: rgb(var(--border-line-color));
    
    height: 35px;
    min-width: 35px;
    &:not(.p-highlight):hover {
      background: rgb(var(--hover-color));
      color: rgb(var(--fc))
    }
  }

  & .p-paginator-first {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  & .p-paginator-last {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  & .p-paginator-page {
    border-color: rgb(var(--border-line-color));
    height: 35px;
    min-width: 35px;

    &:not(.p-highlight):hover {
      background: rgb(var(--hover-color));
      color: rgb(var(--fc))
    }

    &.p-highlight {
      background: rgb(var(--active-color));
      color: rgb(var(--fc))
    }
  }
}