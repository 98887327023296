.read-only input {
  pointer-events: none;
  width: 100%;
  min-width: 150px;
}

.text-xs input {
  font-size: var(--fs-xs);
}

input {
  background: rgb(var(--input-bg));
  color: rgb(var(--fc));
  border-color: rgb(var(--border-line-color));
  &:enabled:focus {
    box-shadow: inherit;
    border-color: rgb(var(--focus-border-color)) !important;
  }
}