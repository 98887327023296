.oy-treetable {
  overflow: initial;
  
  & .p-treetable-wrapper {
    @apply scrollbar-light;
  }

  & .p-treetable-header {
    background: rgb(var(--admin-bg));
    border-color: rgb(var(--border-line-color));
    color: rgba(var(--fc), .5);
    font-weight: 500;
  }

  // HEADER
  & .p-treetable-thead > tr > th {
    background: rgb(var(--admin-bg));
    border-color: rgb(var(--border-line-color));
    text-transform: uppercase;
    font-size: var(--fs-xs);
    font-family: var(--ff-semibold);
  }
  
  // ROW
  & .p-treetable-tbody > tr {
    background: rgb(var(--admin-bg));
    color: rgb(var(--fc));
    font-size: var(--fs-xs);
    font-family: var(--ff-regular-light);

    &.expanded {
      background: rgb(var(--menu-bg));
    }

    // CELLS
    & td {
      border-color: rgb(var(--border-line-color));
    }
  }

  & .p-column-title {
    margin-right: 15px;
  }

}