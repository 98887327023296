/* REGULAR */
@font-face {
  font-family: 'Helvetica Regular';
  font-style: normal;
  font-weight: normal;
  src:
    local('Helvetica Regular'),
    url('helvetica.regular.otf') format('opentype');
}

@font-face {
  font-family: 'Helvetica Regular Thin';
  font-style: normal;
  font-weight: 100;
  src:
    local('Helvetica Regular Thin'),
    url('helvetica.regular.otf') format('opentype');
}

@font-face {
  font-family: 'Helvetica Regular Light';
  font-style: normal;
  font-weight: 300;
  src:
    local('Helvetica Regular Light'),
    url('helvetica.regular.otf') format('opentype');
}

@font-face {
  font-family: 'Helvetica Regular Medium';
  font-style: normal;
  font-weight: 500;
  src:
    local('Helvetica Regular Medium'),
    url('helvetica.regular.otf') format('opentype');
}
