// Default
.p-button {
  border: 0;

  &--default {
    background-color: rgb(var(--secondary-color));
  }

  &--bars {
    padding: 0;
    font-size: 28px;
    background-color: transparent;
    color: rgb(91, 107, 121);
    &:hover {
      background-color: rgb(var(--menu-item-bg-hover));
    }
    &:enabled:focus {
      box-shadow: inherit;
    }
  }

  &--spin-icon:hover .p-button-icon {
    @apply animate-spin;
  }

  &--w-full button {
    width: 100%;
    flex-grow: 1;
  }
}
