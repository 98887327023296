.p-overlaypanel {
  color: rgb(var(--fc));
  background: rgb(var(--overlay-bg));

  &:not(.p-overlaypanel-flipped) {
    &:before {
      border-bottom-color: rgb(var(--overlay-bg));
    }

    &:after {
      border-bottom-color: rgb(var(--overlay-bg));
    }
  }

  &.p-overlaypanel-flipped {
    &:before,&:after {
      border-top-color: rgb(var(--overlay-bg));
    }
  }
}