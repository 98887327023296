@charset "UTF-8";

/* 1. Fuentes */
@import 'fonts/all';

/* 2. Variables globales, mixings, funciones, reset normalizador css */
@import 'base/all';

/* 3. Vistas de secciones */
@import 'views/all';

/* 4. Componentes */
@import 'components/all';

/* 5. Iconos */
@import 'icons/all';

/* 6. Elementos UI: Elementos base de HTML (Formularios, Botones) y Componentes del UI utilizado (Materials) */
@import 'ui/all';

/* 7. Themes (Dark) */
@import 'modes/all';