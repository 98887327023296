/* BOLD */
@font-face {
  font-family: 'Helvetica Bold';
  font-style: normal;
  font-weight: normal;
  src:
    local('Helvetica Bold'),
    url('helvetica.bold.otf') format('opentype');
}

/* SEMI BOLD */
@font-face {
  font-family: 'Helvetica Semi Bold';
  font-style: normal;
  font-weight: 600;
  src:
    local('Helvetica Semi Bold'),
    url('helvetica.bold.otf') format('opentype');
}
