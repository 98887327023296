@layer tw-base, primeng, tw-components, tw-utilities, tw-variants;

@import 'tailwindcss/base.css' layer(tw-base);

// Es necesario importar el theme de primeng justo despues de la base de tailwind para evitar bugs visuales.
@import 'primeng/resources/primeng.min.css';
@import 'primeng/resources/themes/tailwind-light/theme.css';

@import 'tailwindcss/components.css' layer(tw-components);
@import 'tailwindcss/utilities.css' layer(tw-utilities);
@import 'tailwindcss/variants.css' layer(tw-variants);

@import "quill/dist/quill.core.css";
@import "quill/dist/quill.snow.css";

@layer tw-base {
  *,
  ::before,
  ::after {
    border-color: inherit;
  }
}

@layer tw-components {
  // tailwind component classes here
}

@layer tw-utilities {
  // tailwind utitlity classes here
}

.loading-effect {
  @apply opacity-80;
  @apply animate-pulse;
  @apply pointer-events-none;
}