.p-togglebutton.p-button {

  background: rgb(var(--fifth-color));
  opacity: .5;
  color: white;

  & .p-button-icon {
    color: white;
  }

  &.p-highlight {
    background: rgb(var(--fifth-color));
    opacity: 1;
  }

  &.p-disabled {
    opacity: .1;
  }

}