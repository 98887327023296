.p-menu {
  z-index: 1200 !important;

  &.p-menu-overlay {
    background: rgb(var(--menu-bg));
    color: rgb(var(--fc))
  }

  & .p-menuitem-separator {
    border-color: rgb(var(--border-line-color))
  }

  & .p-submenu-header {
    background: transparent;
    color: gray;
  }

  & .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    background: rgb(var(--menu-item-bg-hover));
    & .p-menuitem-link .p-menuitem-text {
      color: rgb(var(--fc))
    }
  }

  & .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: rgb(var(--fc))
  }
}