// Fix radius izquierdo al usar un inputgroup con un password
.p-inputgroup > p-password > .p-password.p-inputwrapper {
  width: 100%;

  .p-inputtext {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

}

.p-password:not(.stay-light).p-inputwrapper input {
  background-color: rgb(var(--input-bg));
  border-color: rgb(var(--border-line-color));
  color: rgb(var(--fc));
  letter-spacing: 1px;
}

p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext {
  border-color: rgb(var(--input-border-error-color)) !important;
}


