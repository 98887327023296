.admin {
  @apply antialiased;
  display: flex;
  min-height: 100vh;
  background-color: rgb(var(--admin-bg));

  &__header {
    display: flex;
    position: fixed;
    left: auto;
    background-color: rgb(var(--header-bg));
    backdrop-filter: blur(8px);
    z-index: 1000;
    box-shadow: none;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    right: 0px;
    top: 0px;
    transition:
      width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
      margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    width: 100%;

    &--full {
      flex-shrink: 0;
      margin-left: 280px;
      width: calc(100% - 280px);
    }

    &--slim {
      width: calc(100% - 90px) !important;
    }

    @screen md {
      @apply admin__header--full;
    }
  }

  &__menu {
    transition: width .2s ease-in-out;
    background: rgb(var(--menu-bg));
    color: rgb(var(--menu-color));
    border: 0;
    white-space: nowrap;
    z-index: 1200;
    width: 0;
    height: 100%;
    position: fixed;

    &--slim {
      box-sizing: border-box !important;
      flex: 0 0 auto !important;
      width: 90px !important;
      box-shadow: rgba(19, 25, 32, 0.08) 0px 8px 24px;
      border-right: rgb(var(--menu-border-color));
    }

    &--full {
      box-shadow: none;
      flex-shrink: 0;
      width: 280px;
      box-shadow: rgba(19, 25, 32, 0.08) 0px 8px 24px;
      border-right: rgb(var(--menu-border-color));
    }

    @screen xs {
      padding: 0;
    }

    @screen md {
      @apply admin__menu--full;
    }
  }

  &__content {
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    position: relative;
    min-height: calc(-74px + 100vh);
    display: flex;
    flex-direction: column;
    margin-top: 74px;
    transition:
      width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
      margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

    &--slim {
      width: calc(100% - 90px);
      margin-left: 90px;
    }

    &--full {
      width: calc(100% - 280px);
      margin-left: 280px;
    }

  }
}

.admin-view {
  padding: 24px 24px;
  @screen xs {
    padding: 24px 30px;
  }
  @screen md {
    padding: 24px 35px;
  }
}
