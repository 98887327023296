.p-inputgroup {
  &:has(.p-inputtext.ng-dirty.ng-invalid) .p-inputgroup-addon {
    border-color: #f0a9a7;
    & i {
      color: rgb(var(--error-strong));
    }
  }

  &:has(p-password.ng-dirty.ng-invalid) .p-inputgroup-addon {
    border-color: #f0a9a7;
    & i {
      color: rgb(var(--error-strong));
    }
  }

  &:focus-within .p-inputgroup-addon {
    border-color: rgb(var(--focus-border-color)) !important;
    & i {
      color: var(--text-fourth) !important;
    }
  }

  .p-inputgroup-addon {
    padding: 0.75rem 0.1rem 0.75rem 1rem;
    min-width: 1rem;
    transition: none;
    background: rgb(var(--input-bg));
    border-color: rgb(var(--border-line-color)) !important;;
  }

  &--sm {
    & .p-inputgroup-addon {
      padding: 0.6rem 0.1rem 0.65rem 0.65rem;
    }

    & .p-inputtext {
      padding: 0.6rem 0.65rem;
    }
  }
}
