.p-fileupload {

  & .p-fileupload-buttonbar {
    background: rgb(var(--menu-bg));
    border: 1px solid rgb(var(--border-line-color));
  }

  & .p-fileupload-content {
    background: rgb(var(--admin-bg));
    border: 1px solid rgb(var(--border-line-color));
    color: rgb(var(--fc))
  }

}